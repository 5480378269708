import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import {
  FdAccordion,
  FdTypography,
  FdIconsV5,
  FdButton,
} from '@fifthdomain/fe-shared';

const BrowseCatalog = () => {
  const { ShoppingCart } = FdIconsV5;
  const theme = useTheme();
  return (
    <Box mb={1}>
      <FdAccordion
        variant="info"
        startAdornmentIcon={() => <ShoppingCart />}
        summary={() => (
          <FdTypography variant="subtitle1">Need more challenges?</FdTypography>
        )}
        endAdornment
        showToggleButton={false}
        content={() => (
          <Box>
            <FdTypography variant="body2">
              <span
                style={{
                  color: theme.palette.common.black,
                }}
              >
                Explore the FifthDomain Challenges Store! Click the “Browse
                Store” button below to access more challenges to add to your
                organisation’s library.
              </span>
            </FdTypography>
            <Box mt={2}>
              <FdButton
                onClick={() =>
                  window.open(
                    `${window.location.origin}/org/content-lease-catalogue`,
                    '_blank',
                  )
                }
              >
                Browse Store
              </FdButton>
            </Box>
          </Box>
        )}
        open
      />
    </Box>
  );
};

export default BrowseCatalog;

import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FdTypography, FdCard, FdSkeleton } from '@fifthdomain/fe-shared';
import { DonutStacked, DonutGraphLegend, LevelTooltip } from '../Charts';
import { upperCaseFirstLetter } from '../../shared/utils/stringUtils';
import { getDifficultyLevel } from '../../shared/utils/difficultyMapping';
import {
  SPECIALTY_COLOR,
  PROFICIENCY_LEVELS,
  PROFICIENCY,
} from '../../constants';
import { TASK_TYPE_COLOR } from '../../shared/utils/taskUtils';

const CompositionGraphs = ({ orgDetails, allTasks, loading }) => {
  const [catalogue, setCatalogue] = useState();
  const [donutToolTipData, setDonutToolTipData] = useState(undefined);
  const [showDonutGraphCategoryTooltip, setShowDonutGraphCategoryTooltip] =
    useState(false);
  const [showDonutGraphDifficultyTooltip, setShowDonutGraphDifficultyTooltip] =
    useState(false);
  const [showDonutGraphTaskTypeTooltip, setShowDonutGraphTaskTypeTooltip] =
    useState(false);

  const fdCatalogue = catalogue
    ? allTasks?.filter((task) => task?.assigned?.includes(catalogue))
    : allTasks;

  // Proficiency Composition
  const donutGraphProficiencyData = _.chain(
    allTasks
      ?.map((at) => ({
        id: at.difficulty,
        label:
          at?.difficulty > 5
            ? getDifficultyLevel(at?.difficulty)
            : PROFICIENCY_LEVELS[at?.difficulty],
      }))
      .filter((dv) => dv?.id),
  )
    .groupBy((i) => i?.label)
    .map((items, label) => ({
      id: label,
      label,
      value: items.length,
      color: PROFICIENCY[label]?.color,
    }))
    .value();
  const donutGraphDifficultyDataColors = donutGraphProficiencyData?.map(
    (d) => d.color,
  );

  // Specialty Composition
  const donutGraphSpecialtyData = _.chain(
    allTasks
      .map((t) => ({
        id: t?.specialtyId,
        name: t?.specialty?.name,
      }))
      .filter((tc) => tc?.id),
  )
    .groupBy((i) => i?.id)
    .map((items, id) => ({
      id,
      label: items[0].name,
      value: items.length,
      color: SPECIALTY_COLOR[items[0]?.name] || '',
    }))
    .value();
  const donutGraphCategoryDataColors = donutGraphSpecialtyData?.map(
    (d) => d.color,
  );

  // Task type Composition
  const getTasksByType = (_tasks, _type) =>
    _tasks?.filter((t) => _type === t.type);

  const donutGraphTypeData = (_allTasks) => {
    const taskType = [...new Set(_allTasks.map((item) => item?.type))];
    const taskTypeValue = [];
    for (let i = 0; i < taskType?.length; i++) {
      taskTypeValue.push({
        id: taskType[i],
        label: upperCaseFirstLetter(taskType[i]),
        value: getTasksByType(_allTasks, taskType[i])?.length,
        color: TASK_TYPE_COLOR[upperCaseFirstLetter(taskType[i])],
      });
    }
    return taskTypeValue;
  };
  const donutGraphTypeDataColors = donutGraphTypeData(fdCatalogue).map((d) => {
    return d.color;
  });

  const HoverInfo = ({ value }) => {
    return (
      <Box
        width="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        ml={2}
      >
        <FdTypography variant="subtitle1" color="secondary">
          {` Hover over the ${value} segment to see more details.`}
        </FdTypography>
      </Box>
    );
  };
  HoverInfo.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.string.isRequired,
  };

  return (
    <FdCard variant="outlined">
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={2}>
          <Box mt={2}>
            <FdTypography variant="subtitle1">
              Professional Specialty composition
            </FdTypography>
          </Box>
          <Box display="flex" flexDirection="row" mt={2} mb={2}>
            <FdSkeleton
              loading={loading}
              variant="circle"
              height={130}
              width={140}
            >
              <Box height="200px" width="200px" mr={2}>
                <DonutStacked
                  data={donutGraphSpecialtyData}
                  colors={donutGraphCategoryDataColors}
                  onHover={(_data) => {
                    setDonutToolTipData(_data?.data);
                    setShowDonutGraphCategoryTooltip(true);
                  }}
                  onLeave={() => {
                    setDonutToolTipData(undefined);
                    setShowDonutGraphCategoryTooltip(false);
                  }}
                />
              </Box>
            </FdSkeleton>
            {showDonutGraphCategoryTooltip ? (
              <LevelTooltip
                data={donutToolTipData}
                totalTasks={fdCatalogue.length}
              />
            ) : (
              <HoverInfo value="specialty" />
            )}
          </Box>
          <FdSkeleton loading={loading} variant="text">
            <DonutGraphLegend data={donutGraphSpecialtyData} />
          </FdSkeleton>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" mb={2}>
          <Box mt={2}>
            <FdTypography variant="subtitle1">
              Proficiency composition
            </FdTypography>
          </Box>
          <Box display="flex" flexDirection="row" mt={2} mb={2}>
            <FdSkeleton
              loading={loading}
              variant="circle"
              height={130}
              width={140}
            >
              <Box height="200px" width="200px" mr={2}>
                <DonutStacked
                  data={donutGraphProficiencyData}
                  colors={donutGraphDifficultyDataColors}
                  onHover={(_data) => {
                    setDonutToolTipData(_data?.data);
                    setShowDonutGraphDifficultyTooltip(true);
                  }}
                  onLeave={() => {
                    setDonutToolTipData(undefined);
                    setShowDonutGraphDifficultyTooltip(false);
                  }}
                />
              </Box>
            </FdSkeleton>
            {showDonutGraphDifficultyTooltip ? (
              <LevelTooltip
                data={donutToolTipData}
                totalTasks={fdCatalogue.length}
              />
            ) : (
              <HoverInfo value="proficiency" />
            )}
          </Box>
          <FdSkeleton loading={loading} variant="text">
            <DonutGraphLegend data={donutGraphProficiencyData} />
          </FdSkeleton>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column" mb={2}>
          <Box mt={2}>
            <FdTypography variant="subtitle1">
              Challenge Type composition
            </FdTypography>
          </Box>
          <Box display="flex" flexDirection="row" mt={2} mb={2}>
            <FdSkeleton
              loading={loading}
              variant="circle"
              height={130}
              width={140}
            >
              <Box height="200px" width="200px" mr={2}>
                <DonutStacked
                  data={donutGraphTypeData(fdCatalogue)}
                  colors={donutGraphTypeDataColors}
                  onHover={(_data) => {
                    setDonutToolTipData(_data?.data);
                    setShowDonutGraphTaskTypeTooltip(true);
                  }}
                  onLeave={() => {
                    setDonutToolTipData(undefined);
                    setShowDonutGraphTaskTypeTooltip(false);
                  }}
                />
              </Box>
            </FdSkeleton>
            {showDonutGraphTaskTypeTooltip ? (
              <LevelTooltip
                data={donutToolTipData}
                totalTasks={fdCatalogue.length}
              />
            ) : (
              <HoverInfo value="type" />
            )}
          </Box>
          <FdSkeleton loading={loading} variant="text">
            <DonutGraphLegend data={donutGraphTypeData(fdCatalogue)} />
          </FdSkeleton>
        </Box>
      </Box>
    </FdCard>
  );
};

CompositionGraphs.propTypes = {
  allTasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  orgDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
};
CompositionGraphs.defaultProps = {
  allTasks: [],
  orgDetails: [],
  loading: false,
};

export default CompositionGraphs;

import Details from './Details';
import Competencies from './Competencies';
import Flag from './Flag';
import Hints from './Hints';
import Attachments from './Attachments';
import Solution from './Solution';
import SolutionWalkthrough from './SolutionWalkthrough';
import Lab from './Lab';

export {
  Details,
  Competencies,
  Flag,
  Hints,
  Attachments,
  Solution,
  SolutionWalkthrough,
  Lab,
};
